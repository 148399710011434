import React from "react";
import { graphql } from "gatsby";
import SiteLayout from "../components/SiteLayout";
import moment from "moment";

//import RecentPosts from "../components/RecentPosts";
import Seo from "../components/Seo";
import Wysiwyg from "../components/Wysiwyg";
import Block from "../components/Block";
import PriceHistory from "../components/PriceHistory";
import style from "../styles/post.module.sass";
import Breadcrumb from "../components/BreadCrumb";
import { formatDateFromTStoIso, getTypeByTags } from "../utils";
import RecentPostsWidget from "../components/RecentPostsWidget";

const Post = props => {
    const {
        location,
        data: {
            wpgraphql: { post },
        },
    } = props;
    const {
        title,
        link,
        content,
        featuredImage,
        slug,
        tags: { nodes: tags },
    } = post;

    let seoImage = null;

    if (post.seo.opengraphImage) {
        seoImage = post.seo.opengraphImage;
    }

    if (seoImage === null && featuredImage) {
        seoImage = featuredImage.sourceUrl;
    }

    return (
        <SiteLayout location={location}>
            <Seo
                title={post.seo.title || title}
                description={`${post.seo.metaDesc}`}
                image={
                    post.seo.opengraphImage
                        ? post.seo.opengraphImage
                        : featuredImage
                        ? featuredImage.sourceUrl
                        : ""
                }
                url={link}
                schema={JSON.stringify({
                    "@context": "http://schema.org/",
                    "@type": "BlogPosting",
                    headline: `${post.seo.title || title}`,
                    description: `${post.seo.metaDesc}`,
                    datePublished: `${formatDateFromTStoIso(post.date)}`,
                    dateModified: `${formatDateFromTStoIso(post.modified)}`,
                    image: [
                        post.seo.opengraphImage
                            ? post.seo.opengraphImage
                            : featuredImage
                            ? featuredImage.sourceUrl
                            : "",
                    ],
                    author: {
                        "@type": "Person",
                        name: `${post.author.name}`,
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "Kapitol Gold",
                        logo: {
                            "@type": "ImageObject",
                            url:
                                "https://kapitolgold.cz/icons/icon-512x512.png",
                        },
                    },
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": `${link}`,
                    },
                })}
            />

            <div
                className={style.postTitle}
                style={
                    featuredImage
                        ? { backgroundImage: `url(${featuredImage.sourceUrl})` }
                        : {}
                }
            >
                <div className={style.inner}>
                    <Breadcrumb
                        color={"white"}
                        mt={30}
                        crumbs={[
                            {
                                label: "Články",
                                link: "/zajimavosti-o-investicnich-kovech",
                            },
                            { label: title, link: slug },
                        ]}
                        leftIndent={false}
                        fullWidth
                    />
                    <h1>{title}</h1>
                    <p className={style.date}>
                        {moment(post.date).format("D. M. YYYY")}
                    </p>
                </div>
            </div>

            <Block>
                <Wysiwyg size={"text"}>{content}</Wysiwyg>
            </Block>
            <PriceHistory type={getTypeByTags(tags)} variant="blog" />
            <RecentPostsWidget />
            {/*<RecentPosts posts={nodes[0].posts} />*/}
        </SiteLayout>
    );
};

export default Post;

export const pageQuery = graphql`
    query GET_POST($id: ID!) {
        wpgraphql {
            post(id: $id) {
                id
                title
                link
                date
                modified
                seo {
                    title
                    metaDesc
                    opengraphImage
                }
                content
                uri
                slug
                featuredImage {
                    sourceUrl(size: LARGE)
                }
                author {
                    name
                    slug
                    avatar {
                        url
                    }
                }
                tags {
                    nodes {
                        name
                        link
                    }
                }
                categories {
                    nodes {
                        name
                        link
                        posts {
                            nodes {
                                id
                                title
                                link
                                excerpt
                                featuredImage {
                                    sourceUrl(size: MEDIUM)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
